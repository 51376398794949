<!--suppress ALL -->
<template>
  <div id="contact">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center mb-5">
          <p>Ne soyez pas timides, nous ne mordons pas !</p>
          <div class="contact-title">CONTACTEZ-NOUS</div>
        </div>
        <div class="col-6 text-center">
          <div class="lieu-title playfairdisplay">Siège social</div>
          <p class="mb-0">+33 1 42 30 00 00</p>
          <p class="mb-0">34 avenue des Champs-Élysées</p>
          <p>75008 Paris</p>
          <a
            href="https://www.google.fr/maps/place/34+Av.+des+Champs-%C3%89lys%C3%A9es,+75008+Paris/@48.8700028,2.3060998,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66fc459e46749:0xb0283b11a8260b73!8m2!3d48.8699993!4d2.3082938"
            target="_blank"
            class="googleMaps"
            ><img src="../assets/images/icon/location-pin.svg" alt="" /> GOOGLE
            MAPS</a
          >
        </div>
        <div class="col-6 text-center">
          <div class="lieu-title playfairdisplay">Studio de création</div>
          <p class="mb-0">+33 1 42 30 00 01</p>
          <p class="mb-0">12 Place des Ma&icirc;tres Vigneron</p>
          <p>92500 Rueil-Malmaison</p>
          <a
            href="https://www.google.fr/maps/place/noproblemo/@48.8741456,2.2293023,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66e42c62abdb7:0x367698abba945fbc!8m2!3d48.8741456!4d2.231491"
            target="_blank"
            class="googleMaps"
            ><img src="../assets/images/icon/location-pin.svg" alt="" /> GOOGLE
            MAPS</a
          >
        </div>

        <div class="col-12 col-lg-9 align-self-center mt-5">
          <div class="row justify-content-center">
            <div class="col-12 col-md-6">
              <div class="position-relative mb-4">
                <input
                  type="text"
                  class="nameCheck check"
                  v-model="form.name"
                  @change="Changed"
                />
                <label
                  ><img src="../assets/images/icon/user.svg" alt="" />Votre
                  nom*</label
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="position-relative mb-4">
                <input
                  type="text"
                  class="firstnameCheck check"
                  v-model="form.firstname"
                  @change="Changed"
                />
                <label
                  ><img src="../assets/images/icon/user.svg" alt="" />Votre
                  prénom</label
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="position-relative mb-4">
                <input
                  type="email"
                  class="emailCheck check"
                  v-model="form.email"
                  @change="Changed"
                />
                <label
                  ><img src="../assets/images/icon/at.svg" alt="" />Votre
                  email*</label
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="position-relative mb-4">
                <input
                  type="text"
                  class="subjectCheck check"
                  v-model="form.subject"
                  @change="Changed"
                />
                <label
                  ><img
                    src="../assets/images/icon/pen.svg"
                    alt=""
                  />Sujet</label
                >
              </div>
            </div>
            <div class="col-12">
              <div class="position-relative mb-4">
                <textarea
                  class="messageCheck check"
                  v-model="form.message"
                  @change="Changed"
                />
                <label
                  ><img
                    src="../assets/images/icon/text.svg"
                    alt=""
                  />Message*</label
                >
              </div>
            </div>
            <div class="col-12 col-sm-5 col-lg-4" v-if="this.valid">
              <button class="bt-hover" v-on:click="PostData">
                CONTACTEZ-NOUS
              </button>
            </div>
            <div class="col-12 col-sm-5 col-lg-4" v-if="!this.valid">
              <p v-html="envoie">{{ envoie }}</p>
            </div>
            <div class="col-12">
              <p class="text-center text-red" v-html="errors">{{ errors }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const querystring = require("querystring");

export default {
  data() {
    return {
      form: {
        name: "",
        firstname: "",
        email: "",
        subject: "",
        message: "",
      },
      envoie: "Message envoyé",
      errors: "",
      valid: true,
    };
  },
  methods: {
    regExp: function(texte) {
      let regex = /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ()&/!?:@"'$*€=+-._-\s]/g;
      let corresp = regex.exec(texte);

      if (corresp === null) return true;
      return false;
    },
    regEmailExp: function(texte) {
      let regex = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,6}/g;
      let corresp = regex.exec(texte);

      if (corresp === null) return true;
      return false;
    },
    PostData: function() {
      this.errors = "";
      this.removeClass(".check", "errorCheck");

      if (this.regExp(this.form.name.trim())) {
        this.errors = 'Veuillez vérifier le champ "Your name"';
        this.addClass(".nameCheck", "errorCheck");
        return;
      }

      if (this.regEmailExp(this.form.email.trim())) {
        this.errors = 'Veuillez vérifier le champ "Your email"';
        this.addClass(".emailCheck", "errorCheck");
        return;
      }

      if (this.regExp(this.form.message.trim())) {
        this.errors = 'Veuillez vérifier le champ "Your message"';
        this.addClass(".messageCheck", "errorCheck");
        return;
      }

      console.log("axios !");

      axios
        .post(`api.php`, querystring.stringify(this.form))
        .then((response) => {
          if (response.data == "") {
            this.valid = false;
            this.envoie = "Message envoyé";
          } else {
            this.envoie = response.data;
          }

          console.log("response:");
          console.log(response.data);
        })
        .catch((e) => {
          console.log("error: " + e);
          this.errors =
            "Une erreur c'est produite lors de l'envois de l'email.<br /> Vous pouvez toujours envoyer manuellement à <a href=\"mailto:jerome@noproblemo.eu\">jerome@noproblemo.eu</a>.";
        });
    },
    addClass: function(selector, myClass) {
      // get all elements that match our selector
      let elements = document.querySelectorAll(selector);

      // add class to all chosen elements
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add(myClass);
      }
    },
    removeClass: function(selector, myClass) {
      // get all elements that match our selector
      let elements = document.querySelectorAll(selector);

      // remove class from all chosen elements
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove(myClass);
      }
    },
    Changed: function({ target }) {
      if (target.value === "") {
        target.classList.remove("doneLabel");
      } else {
        target.classList.add("doneLabel");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.errorCheck {
  border-bottom-color: red;
}

.text-red {
  color: red;
}

.lieu-title {
  font-size: 26px;
  margin-bottom: 1rem;
}

#contact {
  padding-top: 100px;
}

.contact-title {
  font-size: 42px;
  font-weight: bold;
}

.googleMaps {
  color: white;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
}

/* form */
input,
textarea {
  font-size: 16px;
  padding: 10px 10px 10px 24px;
  display: block;
  color: white;
  background-color: #131314;
  width: 100%;
  border: 1px solid #757575;
  border-radius: 0;
}

textarea {
  min-height: 130px;
  &:focus {
    outline: 0px;
  }
}

input:focus {
  outline: none;
}

/* label */
label {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  background: #131314;
  padding: 0 10px;
  transition: all 0.2s ease;

  img {
    margin-right: 10px;
    transition: all 0.2s ease;
  }
}

/* active */

input:focus ~ label,
input.used ~ label,
textarea:focus ~ label,
textarea.used ~ label,
.doneLabel ~ label {
  top: -12px;
  transform: scale(0.75);
  left: 4px;

  img {
    opacity: 0;
    width: 0;
    margin-right: 0;
  }
}

/* Animations */

@keyframes inputHighlighter {
  from {
    background: #4a89dc;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Button */
.bt-hover {
  display: inline-block;
  position: relative;
  width: 100%;
  color: white;
  background: none;
  padding: 12px 24px;
  margin: 0.3em 0 1em 0;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  border: 1px solid white;
  transition: all 0.3s;

  &:hover {
    background: white;
    color: #131314;
  }

  &:focus {
    outline: 0;
  }
}

/* transition button */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
